<template>
  <v-container style="background-color: white; height: 100vh" className="px-3 pt-10" fluid>
    <v-row style="background-color: white; position: fixed; width: 100%; height: 6.5vh">
      <v-col cols="12">
        <Back style="position: fixed" v-if="!isLoading"/>
      </v-col>
    </v-row>
    <v-row class="pt-9" v-if="!isLoading" className="px-3">
      <v-col className="pt-7 pb-0" cols="12">
        <h1 class="px-4" className="principal-color">Termo de Uso</h1>
      </v-col>
      <v-col>
        <div class="px-4" v-html="template"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Back from '@/components/covid/back-arrow/index'
import UserTermsByUserId from "@/services/security/UserTerms.js";
import {mapMutations} from "vuex";

export default {
  name: "miniMenuCovid",
  components: {
    Back,
  },
  data: () => ({
    isLoading: true,
    template: ''
  }),
  beforeCreate() {
    this._userTermsService = new UserTermsByUserId();
  },
  async created() {
    this.loading(true)
    await this._userTermsService.GetUserTermsByUserId(this.$auth.user().id)
        .then(response => {
          this.template = response.data.body
        }).catch(error => {
          this.template = '<p>Não foi possível carregar Termos de Uso.</p>'
        }).finally(() => {
          this.isLoading = false
          this.loading(false)
        })
  },
  methods: {
    ...mapMutations(["loading"])
  }
}
</script>

<style scoped>

/*background effect blur*/
.bg-glass-effect {
  background: rgba(255, 255, 255, 1.0) !important;
  height: 100vh;
}

.principal-color {
  color: #4A499B;
}

</style>